import React from "react"
import styled from "styled-components"

import Form from './adventkalender/form'
import { Section, SectionInner } from "./section"


const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap:nowrap;
  justify-content: flex-end;
  text-align: left;

  @media (max-width: 1159px) {
    align-items: center;
    justify-content: center;
  }
`


const HelpCenter = styled.div`
  margin-bottom: 20px;
  margin-top: 0;
  padding-top: 15px;

  @media (max-width: 1159px) {
    margin: 24px 0;
    order: 2;
  }

  a{
    color: #8a8b85;
    font-size: 14px;

    &:hover {
      h4 {
        text-decoration: underline;
      }
    }

    img {
      display: inline-block;
      height: 32px;
      margin-right: 8px;
      width: 32px;
      vertical-align: middle;
    }
  }

  h4 {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin: 0;
    vertical-align: middle;
  }
`


const Logo = styled(Col)`
  img{
    display: block;
    height: 52px;
    margin-bottom: 10px;
    object-fit: contain;
    object-position: left;
    width: auto;
  }

  span{
    color: #8a8b85;
    font-size: 14px;
    white-space: nowrap;

    @media (max-width: 1159px) {
      bottom: 24px;
      display: block;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
`


const Links = styled.div`
  @media (max-width: 573px) {
    text-align: center;
  }

  a {
    color: #8a8b85;
    font-size: 16px;
    font-weight: 400;
    padding-right: 20px;

    @media (max-width: 573px) {
      display: inline-block;
    }
  }
`


const SectionFooter = styled(Section)`
  background-color: ${props => props.theme.greenColor};
  border-bottom: 40px solid ${props => props.theme.backgroundAltColor};
  color: #fff;
  padding-bottom: 56px;
  padding-top: 56px;

  @media (max-width: 549px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  form {
    display: inline-block;
    position: relative;
  }

  h4 {
    margin: 0 0 24px 0;
    text-align: left;
  }
`


const SectionFooter2 = styled(Section)`
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;

  @media (max-width: 1159px) {
    margin-bottom: 24px;
    padding-bottom: 104px;
    position: relative;
  }

  ${SectionInner} {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1159px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
`


export default ({ noSearch }) => <>
  {!noSearch && <SectionFooter>
    <SectionInner>
      <Form title={<h4>Finde die besten Restaurants in deiner Nähe</h4>} />
    </SectionInner>
  </SectionFooter>}
  <SectionFooter2>
    <SectionInner>
      <Logo>
        <img src={require('../images/footer-logo.svg')} alt="mjam.net" />
        <span>&copy; 2019 Mjam.at / Mjam GmbH</span>
      </Logo>
      <Col>
        <HelpCenter>
          <a href="https://www.mjam.net/kontakt/">
            <img src={require('../images/help-center.svg')} alt="Hilfe Center" />
            <h4>Hilfe Center</h4>
          </a>
        </HelpCenter>
        <Links>
          <a href="https://www.mjam.net/kontakt/">KONTAKT</a>
          <a href="https://www.mjam.net/cookies/">COOKIES</a>
          <a href="https://www.mjam.net/agb/">AGB</a>
          <a href="https://www.mjam.net/impressum/">IMPRESSUM</a>
          <a href="https://www.mjam.net/jobs/">JOBS</a>
          <a href="https://www.mjam.net/datenschutz/">DATENSCHUTZ</a>
        </Links>
      </Col>
      <Col>
        <Links>
          <a href="https://blog.mjam.net/" target="_blank" rel="noopener noreferrer">Mjam Blog</a>
          <a href="https://www.facebook.com/mjam.at/" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://www.instagram.com/mjam_at/" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://twitter.com/mjam" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://www.youtube.com/channel/UCf6teH9Fvhrzch-OLGiwsRg" target="_blank" rel="noopener noreferrer">YouTube</a>
        </Links>
      </Col>
    </SectionInner>
  </SectionFooter2>
</>