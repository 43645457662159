import styled from "styled-components"


export const Section = styled.section`
  background-color: ${props => props.bgAlt ? props.theme.backgroundAltColor : props.theme.backgroundColor};
  text-align: center;
`


export const SectionInner = styled.div`
  margin: 0 auto;
  max-width: 1300px;
`