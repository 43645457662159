import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'


const theme = {
  backgroundColor: '#FFFFFF',
  backgroundAltColor: '#F8F8F8',
  greenColor: '#8FBD07',
  yellowColor: '#FCD700',
  textColor: '#333333',
  textSoftColor: '#505050',
  textMutedColor: '#8A8A8A',
  fonts: {
    desktop: {
      h1: '42px',
      h2: '26px',
      h3: '22px',
      h4: '20px',
    },
    mobile: {
      h1: '36px',
      h2: '22px',
      h3: '22px',
      h4: '20px',
    },
  }
}


const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.textColor};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px;

    a {
      color: ${props => props.theme.greenColor};
      text-decoration: none;
    }

    h1, h2, h3, h4 {
      font-weight: bold;
    }

    h1 {
      font-size: ${props => props.theme.fonts.desktop.h1};

      @media (max-width: 499px) {
        font-size: ${props => props.theme.fonts.mobile.h1};
      }
    }

    h2 {
      font-size: ${props => props.theme.fonts.desktop.h2};

      @media (max-width: 499px) {
        font-size: ${props => props.theme.fonts.mobile.h2};
        line-height: 28px;
      }
    }

    h3 {
      font-size: ${props => props.theme.fonts.desktop.h3};

      @media (max-width: 499px) {
        font-size: ${props => props.theme.fonts.mobile.h3};
      }
    }

    h4 {
      font-size: ${props => props.theme.fonts.desktop.h4};

      @media (max-width: 499px) {
        font-size: ${props => props.theme.fonts.mobile.h4};
      }
    }

    p {
      line-height: 25px;
    }

    .pac-container{
      &:before {
        background-color: ${props => props.theme.backgroundColor};
        color: ${props => props.theme.greenColor};
        content: "Suchergebnisse";
        display: block;
        font-weight: 400;
        font-size: 11px;
        padding: 8px 16px;
        text-transform: uppercase;
        display: block;
      }

      .pac-item{
        padding: 12px 15px 12px 20px;
        font-size: 13px;
        line-height: 1.4;
        font-family: Source Sans Pro,sans-serif;
        cursor: pointer;

        &:hover{
          background: #f2f5ef;
        }

        .pac-icon{
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: 0;
          display: inline-block;
          vertical-align: top;
          background: url(${require('../images/pin.svg')}) 0 no-repeat;
          background-size: contain;
        }
      }
    }
  }
`


const Layout = ({ children }) => {
  useEffect(() => {
    if (process.browser) {
      window.dataLayer = window.dataLayer || [];
    }
  }, [])
  return (
    <>
      <Normalize />
      <GlobalStyle theme={theme} />

      <ThemeProvider theme={theme}>
        <main>{children}</main>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
